<style scoped lang="less">
.full {
  padding: 0;
  margin: 0;
}

#click-scroll-X {
  display: flex;
  align-items: center;
  .left_btn,
  .right_btn {
    font-size: 30px;
    cursor: pointer;
    display: inline-block;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    color: #66b1ff;
  }
  .left_btn:hover,
  .right_btn:hover {
    color: #fff;
  }
}

.home-content {
  width: 100%;
  height: 100vh;
}

.home-header {
  background: #2d507e;
  cursor: default;
  color: #fff;
  padding: 0 30px;
  .header-patient-info {
    display: flex;
    align-items: center;
    height: 4rem;
    .header-patient-info-label {
      height: 100%;
      display: flex;
      flex-direction: column;
      .info-header-item1 {
        height: 28px;
        line-height: 36px;
        .header-name {
          color: #fff;
          font-size: 18px;
        }
        .header-sex {
          color: #fff;
          font-size: 16px;
        }
        .header-label {
          color: #c7d0dc;
        }
      }
      .info-header-item2 {
        height: 32px;
        line-height: 32px;
        .header-label {
          color: #c7d0dc;
        }
      }
    }
    span {
      white-space: nowrap;
    }
  }
  .timeBox {
    display: -webkit-box;
    margin: 0 0.5rem;
    max-width: 88rem;
    height: 4rem;
    overflow-x: scroll;
    white-space: nowrap;
    .timeBoxItem {
      display: flex;
      align-items: center;
      margin-right: -8px;
      min-width: 7.5rem;
      .container-tag {
        margin-left: 5px;
        width: 0;
        height: 0;
        position: relative;
        border: 20px solid;
        border-color: transparent transparent transparent #fff;
      }
      .container-tag::after {
        content: '';
        position: absolute;
        top: -17.5px;
        left: -22px;
        border: 18px solid;
        border-color: transparent transparent transparent #2d507e;
      }
      @media screen and (max-width: 1180px) {
        .container-tag::after {
          top: -17px;
          border: 17px solid;
        }
      }
    }
  }
  /deep/ .timeBox::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1180px) {
    .timeBox {
      width: 80rem;
    }
  }
}

.home-menu {
  min-width: 180px;
  background: #ffffff;
  height: calc(100vh - 64px);
  overflow: auto;
  /deep/ .el-menu {
    height: 100%;
    min-width: 180px;
    .addMenuTitle {
      padding-left: 40px;
      .el-button {
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        .el-icon-circle-plus-outline {
          padding-bottom: 3px;
        }
      }
    }
  }
}

.home-menu::-webkit-scrollbar {
  display: none;
}

.home-main {
  height: calc(100vh - 4rem);
  background: #fff;
}
</style>
<style lang="less">
.add-form {
  .el-dialog {
    width: 1000px !important;
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }

    .el-dialog__body {
      height: 600px;
      overflow-y: auto;
      padding: 0;

      .dialog-btn-wrap {
        height: 50px;
        background-color: #f2f2f2;
        z-index: 100;
        display: flex;
        align-items: center;
        padding-left: 20px;
      }
    }
    .form-wrap {
      margin-top: 20px;
      padding-left: 20px;
      .label-item {
        line-height: 35px;
        .el-radio {
          height: 30px;
          line-height: 5px;
          margin: 0 5px 0 0;

          .el-radio__inner {
            display: none;
          }
        }
      }
      .select-wrap {
        .el-col {
          margin-bottom: 15px;
          .el-button {
            margin-left: 10px;
          }
        }
      }
      .el-divider__text {
        background-color: #f8f8f8;
      }
      .el-row {
        margin-bottom: 15px;
      }
      .el-input {
        width: 250px;
      }
      .el-input__inner {
        border-color: #dcdfe6;
        height: 35px;
      }
      .next {
        margin-bottom: 15px;
      }
      .el-tabs__header {
        position: fixed;
        z-index: 100;
        background: #fff;
      }
      .fixed-btn {
        margin-top: 40px;
        .el-button {
          position: fixed;
          z-index: 500;
        }
      }
      .el-tabs__content {
        .container {
          margin-top: 80px;
        }
      }
    }
  }
}
</style>

<template>
  <el-container class="home-content">
    <el-header class="home-header" height="auto">
      <div class="header-patient-info">
        <div class="header-patient-info-label">
          <div class="info-header-item1">
            <span class="header-name" v-if="currentPatient?.patientsName">
              {{ currentPatient?.patientsName }}&ensp;
            </span>
            <span class="header-sex" v-if="currentPatient?.patientsSex">
              {{ currentPatient?.patientsSex }}&ensp;
            </span>
            <el-button
              round
              @click="jumpHzzx"
              size="mini"
              style="margin-left: 8px; position: absolute; top: 5px"
            >
              患者360
            </el-button>
          </div>
          <div class="info-header-item2">
            <span class="header-label" v-if="currentPatient?.sickArea">
              {{ currentPatient?.sickArea }}&ensp;|&ensp;
            </span>
            <span class="header-label" v-if="currentPatient?.examineDoctor">
              主刀： {{ currentPatient.examineDoctor }}&ensp;
            </span>
            <span class="header-label" v-if="currentPatient?.examineNames">
              {{ currentPatient.examineNames }}&ensp;
            </span>

            <el-button
              v-if="
                (followId && followTittle === '30天随访') ||
                (followId && followTittle === '1年随访')
              "
              round
              size="mini"
              style="font-size: 14px; margin-left: 20px"
              @click="handleReport"
            >
              上报上海瓣膜中心
            </el-button>
          </div>
          <!-- <span style="">当前病人：{{ currentPatient && currentPatient.patientsName }}&ensp;</span>
          <el-button @click="jumpHzzx" type="primary" size="mini" style="margin: 0 10px 0 5px">
            患者360
          </el-button>
          <span>{{ currentPatient && currentPatient.sickArea }}&ensp;</span>
          <span>{{ currentPatient && currentPatient.examineDoctor }}&ensp;</span>
          <span>{{ currentPatient && currentPatient.examineNames }}&ensp;</span>
          <el-button
            v-if="
              (followId && followTittle === '30天随访') || (followId && followTittle === '1年随访')
            "
            size="mini"
            style="font-size: 14px; margin-left: 20px"
            @click="handleReport"
          >
            上报上海瓣膜中心
          </el-button> -->
        </div>

        <div id="click-scroll-X">
          <span class="left_btn" @click="leftSlide" v-show="timeAxisList.length > 8">《</span>
          <div class="timeBox" ref="wrapperCon">
            <div class="timeBoxItem" v-for="(item, index) in timeAxisList" :key="index">
              <div style="z-index: 19">
                <div style="display: flex">
                  {{ item.title }}
                  <span v-if="item.attach?.followType || item.recordName">
                    （
                    <span v-if="item.attach?.followType">{{ item.attach?.followType }}</span>
                    <span v-if="item.recordName">{{ item.recordName }}</span>
                    ）
                  </span>
                </div>
                <div v-if="item.time">{{ item.time?.substr(0, 10) }}&ensp;</div>
              </div>
              <div class="container-tag" v-if="index + 1 !== timeAxisList.length"></div>
            </div>
          </div>
          <span class="right_btn" @click="rightSlide" v-show="timeAxisList.length > 8">》</span>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside class="home-menu" width="auto">
        <el-menu
          class="el-menu-vertical-demo"
          :default-active="menuPath"
          @select="menuSelect"
          :default-openeds="opens"
        >
          <div v-for="(item, index) in followMenu" :key="index">
            <!-- 一级菜单 -->
            <el-menu-item v-if="!item.subMenus" :index="item.url">
              <i class="el-icon-menu"></i>
              <span slot="title">{{ item.menuTitle }}</span>
              <span slot="title" style="font-weight: bold" v-if="item.completion">
                {{ item.completion !== 0 ? +item.completion + '%' : '' }}
              </span>
            </el-menu-item>
            <!-- 二级菜单 -->
            <el-submenu v-else :index="item.url">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>{{ item.menuTitle }}</span>
                <span style="font-weight: bold" v-if="item.completion">
                  {{ item.completion !== 0 ? +item.completion + '%' : '' }}
                </span>
              </template>
              <template v-if="item.menuTitle === '随访记录'">
                <div class="addMenuTitle" v-if="currentPatient.isDead !== '是'">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-circle-plus-outline"
                    @click="addFollow"
                    :disabled="currentPatient.isDead === '是'"
                  >
                    新增随访
                  </el-button>
                </div>
                <div v-for="(ele, i) in item.subMenus" :key="i">
                  <template v-if="ele.followTitle || ele.menuTitle">
                    <el-menu-item :index="item.url + `?stage=${ele.followTitle || ele.menuTitle}`">
                      <i
                        :style="
                          ele.status === '已完成' || ele.status === '已结束'
                            ? 'font-weight: bold;'
                            : ''
                        "
                        :class="
                          ele.status === '已完成' || ele.status === '已结束'
                            ? 'el-icon-check'
                            : 'el-icon-document'
                        "
                      ></i>
                      <span slot="title">
                        {{ ele.followTitle ? ele.followTitle : ele.menuTitle }}
                      </span>
                      <span slot="title" style="font-weight: bold" v-if="ele.completion">
                        {{ ele.completion !== 0 ? +ele.completion + '%' : '' }}
                      </span>
                    </el-menu-item>
                  </template>
                </div>
              </template>
              <template v-else>
                <el-menu-item v-for="(ele, i) in item.subMenus" :key="i" :index="item.url">
                  {{ ele.followTitle ? ele.followTitle : ele.menuTitle }}
                </el-menu-item>
              </template>
            </el-submenu>
          </div>
        </el-menu>
      </el-aside>
      <el-main class="full home-main">
        <router-view :info="currentPatient" :followObj="followObj" :path="followPath" />
      </el-main>
      <el-dialog title="新增随访" class="add-form" :visible.sync="dialogFormVisible" width="20%">
        <div class="dialog-btn-wrap">
          <el-button type="primary" size="mini" @click="saveNextFollow">新增</el-button>
        </div>
        <div class="form-wrap">
          <div class="select-wrap">
            <el-row type="flex" align="middle">
              <el-col :span="5">随访标题</el-col>
              <el-col :span="14">
                <el-input v-model="followTitle" placeholder="请输入随访标题"></el-input>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="5">随访方式</el-col>
              <el-col :span="14">
                <el-radio-group v-model="followType" class="label-item">
                  <el-radio
                    border
                    v-for="(item, index) in followTypeList"
                    :key="index"
                    :label="item.comboValue"
                  >
                    {{ item.comboValue }}
                  </el-radio>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="5">随访时间</el-col>
              <el-col :span="14">
                <el-date-picker
                  v-model="followDate"
                  type="date"
                  align="center"
                  size="mini"
                  unlink-panels
                  placeholder="随访时间"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                ></el-date-picker>
              </el-col>
            </el-row>
            <el-row v-show="followTemplate.length > 0">
              <el-col :span="5" style="margin: 0">随访表单模板</el-col>
              <el-col :span="14">
                <el-row>
                  <el-col :span="24">
                    <el-checkbox-group v-model="followTemplateName">
                      <el-checkbox
                        v-for="(item, index) in followTemplate"
                        :key="index"
                        :label="item.formId"
                      >
                        {{ item.alias }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {},
  props: {},
  data() {
    return {
      currentPatient: {},
      followMenu: [],
      opens: [],
      menuPath: '',
      dialogFormVisible: false,
      followDate: '', //随访时间
      followTitle: '', //随访标题
      followObj: [], //随访记录item follow
      followType: '', //随访方式
      followTypeList: [], //随访方式列表
      followTemplateName: [], //随访模板名称
      followTemplate: [], //随访模板
      timeAxisList: [],
      followPath: '',
      timer: null
    }
  },
  computed: {
    ...mapState(['followId', 'followTittle'])
  },
  created() {
    this.getFollowType()
  },
  beforeDestroy() {
    this.$bus.$off('saveFollowFormInfo')
    this.$bus.$off('getUserFollowInfo')
    this.$bus.$off('getFollowTimeAxis')
  },
  mounted() {
    let operationId = this.getQueryVariable('operationId')
    this.$store.dispatch('setCurrentPatientAct', operationId, false)
    this.getFollowMenu()
    this.getCurrentPatient()
    this.getFollowTimeAxis()
    this.$bus.$on('saveFollowFormInfo', () => {
      this.getFollowMenu()
    })
    this.$bus.$on('getUserFollowInfo', () => {
      this.getCurrentPatient()
    })
    this.$bus.$on('getFollowTimeAxis', () => {
      this.getFollowTimeAxis()
    })
  },
  watch: {
    dialogFormVisible(val) {
      if (val === true) {
        this.getFollowTemplate()
      }
    }
  },
  methods: {
    leftSlide() {
      // 保存滚动盒子左侧已滚动的距离
      let left = this.$refs.wrapperCon.scrollLeft
      let num = 0
      clearInterval(this.timer)
      this.timer = null
      this.timer = setInterval(() => {
        //   !left:已经滚动到最左侧
        //   一次性滚动距离（可调节）
        if (!left || num >= 300) {
          // 停止滚动
          clearInterval(this.timer)
          this.timer = null
          return
        }
        // 给滚动盒子元素赋值向左滚动距离
        this.$refs.wrapperCon.scrollLeft = left -= 30
        // 保存向左滚动距离（方便判断一次性滚动多少距离）
        num += 30
      }, 20)
      // 20：速度（可调节）
    },
    rightSlide() {
      // 保存滚动盒子左侧已滚动的距离
      let left = this.$refs.wrapperCon.scrollLeft
      // 保存元素的整体宽度
      let scrollWidth = this.$refs.wrapperCon.scrollWidth
      // 保存元素的可见宽度
      let clientWidth = this.$refs.wrapperCon.clientWidth
      let num = 0
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        // 已经滚动距离+可见宽度
        // left+clientWidth>=scrollWidth：滚动到最右侧
        // num>=300一次性滚动距离
        if (left + clientWidth >= scrollWidth || num >= 300) {
          clearInterval(this.timer)
          return
        }
        // 给滚动盒子元素赋值向左滚动距离
        this.$refs.wrapperCon.scrollLeft = left += 30
        // 保存向左滚动距离（方便判断一次性滚动多少距离）
        num += 30
      }, 20)
      // 20：速度（可调节）
    },

    handleReport() {
      this.$api
        .get('/center/valve/addPatientValveFollowUp?followId=' + `${this.followId}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg)
          }
        })
    },
    getCurrentPatient() {
      let url = `/v1/webconsole/study/operation/get/${this.$route.query.operationId}`
      this.$api.get(url).then((res) => {
        this.currentPatient = res.data.data
      })
    },
    getQueryVariable(variable) {
      var query = window.location.hash.split('?')[1]
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    },
    getFollowTimeAxis() {
      this.$api
        .get(
          `/v1/webconsole/follow/timeline/${this.$route.query.operationId}/${this.$route.query.moduleCode}`
        )
        .then((res) => {
          if (res.data && res.data.data) {
            this.timeAxisList = res.data.data
            if (this.timeAxisList.length > 0) {
              const timeAxisListNames = this.timeAxisList.map((item) => item?.title)
              if (!timeAxisListNames.includes('入院时间')) {
                this.timeAxisList.splice(0, 0, {
                  name: '入院时间',
                  time: null
                })
              }
              if (timeAxisListNames[2] === '手术时间') {
                this.timeAxisList.splice(2, 1)
              }
              if (!timeAxisListNames.includes('出院时间')) {
                this.timeAxisList.splice(2, 0, { name: '出院时间', time: null })
              }
            }
          }
        })
    },
    jumpHzzx() {
      const { href } = this.$router.resolve({
        path: `/patientCenterInfo?patientsIndex=${this.currentPatient.patientsIndex}`
      })
      window.open(href, '_blank')
    },
    getFollowMenu() {
      this.$api
        .get(
          `/v1/webconsole/follow/menu/${this.$route.query.operationId}/${this.$route.query.moduleCode}`
        )
        .then(({ data: res }) => {
          for (let item of res.data) {
            item.url = `/follow/${item.menuCode}`
            if (item.subMenus && item.subMenus.length > 0) {
              this.opens.push(item.url)
            }
          }
          if (this.$route.path.includes('/follow/info')) {
            this.menuPath = this.$route.path.replace('/info', '')
          } else {
            if (this.$route.path === '/follow/follow') {
              if (this.opens.length > 0) {
                this.menuPath = this.$route.path + `?stage=${this.$route.query.stage}`
              }
            } else {
              this.menuPath = this.$route.path
            }
          }
          res.data.forEach((item) => {
            if (item.menuTitle === '随访记录') {
              localStorage.setItem('followObj', JSON.stringify(item.subMenus))
              this.followObj = item.subMenus
            }
          })
          this.followMenu = res.data
        })
    },
    menuSelect(i) {
      const query = this.$route.query
      const queryParams = {
        moduleCode: query.moduleCode ? `&moduleCode=${query.moduleCode}` : '',
        moduleId: query.moduleId ? `&moduleId=${query.moduleId}` : '',
        operationId: query.operationId ? `&operationId=${query.operationId}` : '',
        followId: query.followId ? `&followId=${query.followId}` : ''
      }
      const constructRoute = (i, params) => {
        const routeQuery = Object.values(params).filter(Boolean).join('')
        if (i.includes('?stage')) {
          this.$router.push(`${i}${routeQuery ? `${routeQuery}` : ''}`)
        } else {
          this.$router.push(`${i}${routeQuery ? `?${routeQuery}` : ''}`)
        }
      }
      if (
        i.includes('/follow/CoronaryInfo') ||
        i.includes('/follow/AorticInfo') ||
        i.includes('/follow/Pacemaker') ||
        i.includes('/follow/Aneurysm') ||
        i.includes('/follow/NerveAvm') ||
        i.includes('/follow/NerveArteryStenosis') ||
        i.includes('/follow/NerveAneurysm') ||
        i.includes('/follow/NerveCe') ||
        i.includes('/follow/NerveCcf') ||
        i.includes('/follow/NurseIntervenes') ||
        i.includes('/follow/Medicate') ||
        i.includes('/follow/Nursing') ||
        i.includes('/follow/Equipment') ||
        i.includes('/follow/ReportPrint') ||
        i.includes('/follow/Pacemaker') ||
        i.includes('/follow/LiverCancer') ||
        i.includes('/follow/Cirrhosis') ||
        i.includes('/follow/PeripheralVessels') ||
        i.includes('/follow/AorticDissection') ||
        i.includes('/follow/AorticAneurysm') ||
        i.includes('/follow/Mitral') ||
        i.includes('/follow/Electrophysiology') ||
        i.includes('/follow/CoronaryInfo') ||
        i.includes('/follow/CarotidStenosis')
      ) {
        i = i.replace(/follow/, 'follow/info')
        this.followPath = i
        constructRoute(i, queryParams)
      } else {
        constructRoute(i, queryParams)
      }
    },
    // 保存计划之外随访
    saveNextFollow() {
      if (!this.followTitle) {
        this.$alert('请填写随访标题', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (!this.followDate) {
        this.$alert('请选择随访时间', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (!this.followType) {
        this.$alert('请选择随访方式', '提示', {
          confirmButtonText: '确定'
        })
        return
      }

      let url = ''
      url = `/v1/webconsole/follow/addtemp/${this.$route.query.operationId}/${
        this.$route.query.moduleCode
      }?title=${this.followTitle}&followDate=${this.dayjs(this.followDate).format(
        'YYYY-MM-DD HH:mm:ss'
      )}&followType=${this.followType}`

      let data = []
      if (this.followTemplate.length > 0) {
        this.followTemplate.forEach((item) => {
          this.followTemplateName.forEach((ele) => {
            if (item.formId === ele) {
              data.push(item)
            }
          })
        })
      }

      this.$api.post(url, data).then((res) => {
        if (res.data && res.data.data) {
          this.dialogFormVisible = false
          this.$message({
            type: 'success',
            message: '新增成功'
          })

          this.getFollowMenu()
        }
      })
    },
    // 新增随访
    addFollow() {
      this.followDate = this.dayjs().format('YYYY-MM-DD')
      this.followTitle = ''
      this.followType = ''
      this.followTemplate = []
      this.followTemplateName = []
      this.dialogFormVisible = true
    },
    getFollowTemplate() {
      this.$api
        .get(
          `/v1/webconsole/eform/follow/temporary/form/${this.$route.query.operationId}/${this.$route.query.moduleCode}`
        )
        .then(({ data: res }) => {
          if (res.data && res.data.length > 0) {
            this.followTemplate = res.data
            this.followTemplate.forEach((item) => {
              this.followTemplateName.push(item.formId)
            })
          }
        })
    },
    // 随访方式
    getFollowType() {
      this.$api.get(`/v1/webconsole/comboData/get/FOLLOW_follow_type`).then(({ data: res }) => {
        this.followTypeList = res.data
      })
    }
  }
}
</script>
